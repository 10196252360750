@import url('https://fonts.googleapis.com/css?family=Raleway:400,500');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: baseline;
}

div {
  position: relative;
  z-index: 2;
}

body {
  background-color: #282c34;
  color: #333;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer {
  width: 450px;
  text-align: center;
  display: block;
  margin: 15px auto 30px auto;
  font-size: small;
  color: #fff;
}

.footer > a {
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  text-decoration: underline;
}

#quote-box {
  border-radius: 3px;
  position: relative;
  margin:8% auto auto auto;
  width: 450px;
  padding: 40px 50px;
  display: table;
  background-color: #fff;
}

.quote-text {
  text-align: center;
  width: 450px;
  height: auto;
  clear: both;
  font-weight: 500;
  font-size: 1.5em;
  color: #282c34;
}

.quote-text > span {
  margin-right: 0.4em;
}

.quote-author {
  width: 450px;
  height: auto;
  clear: both;
  padding-top: 8px;
  font-size: 0.8em;
  text-align: right;
}

.buttons {
  margin-top: 30px;
  display: block;
}

.buttons > button {
  padding: 0px;
  margin-right: 1px;
}

.buttons > button > i {
  /* margin-right: 3px; */
  font-size: 25px;
}

.social-button {
  color: #212529;
}

#new-quote {
  float: right;
  padding: 3px 12px 3px 12px;
}